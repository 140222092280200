<template>
    <div id="appp">
        <div
            @click="toggleDownload"
            class="down-load-btn"
            :class="activeDownloadBtn"
        >
            <img
                v-show="!downloadShow"
                src="@/assets/download.png"
                alt="download"
                class="down-load-img"
            />
            <img
                v-show="downloadShow"
                src="@/assets/downloadBlack.png"
                alt="download"
                class="down-load-img"
            />
            <span class="down-text" :class="activeDownloadText"
                >下载领聚云APP，享更多掌上便捷</span
            >
            <div v-show="downloadShow" class="code-show">
                <img src="@/assets/code.png" alt="code" />
            </div>
        </div>
        <div class="hight-pl"></div>
        <div class="flex-vc">
            <div class="main-area">
                <div class="logo-row">
                    <img src="@/assets/new-logo.jpg" alt="logo" class="logo-img" :class="{'mobileSize' : logoPicSize }" @click="skipMysitefy"/>
                </div>
                <div class="login-area">
                    <div v-show="isLogin" class="vc">
                        <div class="phone-wrap phone-box">
                            <div class="area-code">+86</div>
                            <input
                                type="text"
                                class="phone-input"
                                placeholder="请输入手机号"
                                v-model="phoneMsg"
                                pattern="[0-9]*"
                                maxlength="11"
                            />
                        </div>
                        <div class="phone-wrap password-box">
                            <div class="pass-wd">
                                <p>密码</p>
                                <input
                                    :type="passwordType  ? 'password' : 'text'"
                                    class="phone-input"
                                    placeholder="请输入密码"
                                    v-model="password"
                                    show-password
                                />
                            </div>
                             <template v-if="password.length">
                                <img v-show="passwordType" src="../../assets/passWord.png" @click="passwordLook('password')" />
                                <img v-show="!passwordType" src="../../assets/hidden-img.png" @click="passwordLook('password')" />
                            </template>
                        </div>
                        <div class="phone-wrap password-box">
                            <div class="pass-wd">
                                <p>确定密码</p>
                                <input
                                    :type="confirmationType ? 'password' : 'text'"
                                    class="phone-input"
                                    placeholder="请再次输入密码"
                                    v-model="second_confirmation"
                                    show-password
                                />
                            </div>
                            <template v-if="second_confirmation.length">
                                <img v-show="confirmationType" src="../../assets/passWord.png" @click="passwordLook('second_confirmation')" />
                                <img v-show="!confirmationType" src="../../assets/hidden-img.png" @click="passwordLook('second_confirmation')" />
                            </template>
                        </div>
                        <div class="code-wrap">
                            <input
                                type="text"
                                class="code-input"
                                v-model="codeMsg"
                                placeholder="请输入短信验证码"
                            />
                            <div
                                @click="handleGetCode"
                                class="get-code"
                                :class="
                                    isPhone && isGetCodeState
                                        ? 'getCodeBtnActive'
                                        : ''
                                "
                            >
                                {{ codeText }}
                            </div>
                        </div>
                        <!-- <div class="loginBtn">
                                            <div class="confirmBtn" :loading="loadingBtn" @click="loginStyle==='name'?confirmNext():noteLogin()">登录</div>
                                        </div> -->
                        <div class="loginBtn">
                            <div @click="handleLogin" class="login-btn">
                                立即注册
                            </div>
                        </div>
                        <div v-if="+$route.query.diff === 0 || +$route.query.diff === 1" @click="$router.push({ path: '/regisers',query:{isHas:true}})" class="sign-up">
                            已有账号?
                        </div>
                        <div v-else class="sign-up">
                            已有账号?&nbsp;<a :href="loginUrl" class="link-text"
                                >直接登录</a
                            >
                        </div>
                    </div>
                    <div v-show="!isLogin" class="bind-vc">
                        <div class="bind-wechat">是否现在绑定微信？</div>
                        <div class="btn-group">
                            <div @click="skipWechat" class="skip-btn">跳过</div>
                            <div class="bind-btn">立刻绑定</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="height: 110px"></div>
        <div class="copy-right">
            Copyright © 2016-2022 Mysitefy All rights reserved
        </div>
        <el-dialog :visible.sync="showCanLogin" width="550px" top="35vh">
            <div class="center">
                <div class="tip-text">该手机号已注册，可直接登录</div>
                <a :href="loginUrl" class="go-btn">前往登录</a>
            </div>
        </el-dialog>
        <el-dialog :visible.sync="showLoginSuccess" width="550px" top="35vh">
            <div class="center">
                <div class="tip-text">注册成功！</div>
                <div class="blue-text">请稍后……</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import ajax from "../../js/ajax.js";
import global from "../../../global.js";
export default {
    name: "Login",
    components: {},
    data() {
        return {
            downloadShow: false, // 是否显示下载二维码
            phoneMsg: "", // 输入的手机号
            codeMsg: "", // 验证码
            isPhone: false, // 手机号是否输入正确？
            Countdown: 60, // 默认倒计时60s
            isGetCodeState: true, // 获取验证码按钮是否可点击 默认可点击
            codeText: "获取验证码", // 验证码的文本
            timer: null, // 全局定时器
            showCanLogin: false,
            showLoginSuccess: false,
            isLogin: true, // true:登录界面，false:微信绑定界面
            password: '', //密码
            isPassword: false,
            second_confirmation: '', //确定密码
            passwordType: true, // 密码查看
            confirmationType: true,
            logoPicSize: global.isMobile,
            isLoading:false
        };
    },
    watch: {
        /** *
         * 监听手机号输入
         * * */
        phoneMsg(value) {
            const reg = /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
            if (reg.test(value)) {
                // 正確
                this.isPhone = true;
            } else {
                // 錯誤
                this.isPhone = false;
            }
        },
        password(value){
            const reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^&*`~()-+=]+$)(?![0-9\\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$%^&*`~()-+=]{8,30}$/;
            if (reg.test(value)) {
                // 正確
                this.isPassword = true;
            } else {
                // 錯誤
                this.isPassword = false;
            }
        },
        $route:{
            handler(val){
               this.phoneMsg = val.query.fromLinkjoint
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
        activeDownloadBtn() {
            if (this.downloadShow) {
                return {
                    "active-download-btn-bg": true,
                };
            } else {
                return "";
            }
        },
        activeDownloadText() {
            if (this.downloadShow) {
                return {
                    "active-download-text": true,
                };
            } else {
                return "";
            }
        },
        channerSourse(){
             if(+this.$route.query.diff === 0){
                return '促贸会免费版'
            }else if(+this.$route.query.diff === 1){
                return '促贸会专业版'
            }else{
                return this.$route.query.channerSourse
            }
        },
        loginUrl() {
             switch (global.env) {
                case "dev": // dev
                    return "http://10.10.20.228:3030/";
                case "test": // test
                    return "http://testregister.linkjoint.cn/";
                case "pro": // pro
                    return "https://accounts.mysitefy.com/";
                default:
                    return "http://192.168.2.101:8080/"; // 本机环境默认开发
            }
        },
    },
    mounted() {},
    methods: {
        skipMysitefy() {
            window.location.href='https://www.mysitefy.com/'
        },
          onCopy (val) {
            this.$copyText(val).then(() => {
                this.$message.success("复制成功");
            }, () => {
                this.$message.error("复制失败");
            });
        },
        // 密码查看
        passwordLook(value){
            if (value==='password') {
                this.passwordType = !this.passwordType
            } else {
                this.confirmationType = !this.confirmationType
            } 
        },
        getAlias(siteId) {
            ajax({
                method: "post",
                url: "/api/site/seoAlias",
                data: {
                    site_id: siteId,
                },
            })
                .then(() => {})
                .catch((e) => {
                    console.log(e);
                });
        },
        toggleDownload() {
            this.downloadShow = !this.downloadShow;
        },
        /** *
         * 获取验证码
         * * */
        async handleGetCode() {
            if (!this.isGetCodeState) {
                return;
            }
            if (!this.isPhone) {
                alert("请确定手机号码是否正确!");
                return;
            }
            const res = (+this.$route.query.diff === 0 || +this.$route.query.diff === 1 )? '/api/mini/user/registerCode' : "/api/user/registerCode"
            const { data } = await ajax.post(res, {
                mobile: this.phoneMsg,
            });
            if (data.code === 200) {
                this.handelCountdown();
            }
            if (data.code === 10007) {
                this.showCanLogin = true;
            }
        },
        handelCountdown() {
            /** *
             * 倒计时
             * */
            this.codeText = `${this.Countdown}s后重新获取`;
            this.isGetCodeState = false;
            this.timer = setInterval(() => {
                this.Countdown -= 1;
                this.codeText = `${this.Countdown}s后重新获取`;
                if (this.Countdown === 0) {
                    this.codeText = "获取验证码";
                    this.isGetCodeState = true;
                    this.Countdown = 60;
                    clearInterval(this.timer);
                }
            }, 1000);
        },
        async handleLogin() {
            if(this.isLoading){
                return
            }
            if (!this.isPassword) {
                this.$message.error("请输入密码长度至少8位，必须符合由数字,大写字母,小写字母,特殊符,至少其中三种组成密码（特殊字符只允许 _!@#$%^&*`~()-+=）");
                return
            }
            if (this.password!==this.second_confirmation) {
                this.$message.error("两次输入的密码不一样,请重新输入");
                return
            }
            this.isLoading = true
            const password  = this.$md5(this.password)
            const second_confirmation = this.$md5(this.second_confirmation)
            const res = (+this.$route.query.diff === 0 || +this.$route.query.diff === 1 )? '/api/mini/user/register' : "/api/user/register"
            const { data } = await ajax.post(res, {
                mobile: this.phoneMsg,
                code: this.codeMsg,
                password,
                second_confirmation,
                channel_source: this.channerSourse,

            });

            if (data.code === 10007) {
                // 账号已经存在
                this.showCanLogin = true;
                this.isLoading = false
            }else {
                this.isLoading = false
            }
            // if (data.code === 30003) {
            //     this.$message.error("验证码错误，请重新输入");
            // }
            if (data.code === 200) {
                this.isLoading = false
                if(res === '/api/user/register'){
                    this.skipWechat()
                } else {
                    if(data.data.status === '该手机号已注册'){
                        this.$router.push({ path: '/regisers',query:{status:true}})
                    }else {
                        this.$router.push({ path: '/regisers'})
                    }
                }
                // eslint-disable-next-line no-undef
                gtag('event', 'generate_lead', {'event_category': 'engagement',});
            }else {
                this.isLoading = false
            }
        },
        skipWechat() {
            this.isLogin = true;
            this.showLoginSuccess = true;
            setTimeout(() => {
                this.goToSignUp();
            }, 500);
        },
        goToSignUp() {
            // 去登录页
            this.$router.push('/')
            // window.location = this.loginUrl;
        },
    },
};
</script>

<style lang="scss">
#appp {
    position: relative;

    min-height: 100vh;
    background-image: url("../../assets/bg.png");
    background-repeat: repeat;
    background-size: contain;
    padding: 0 15px;
    .flex-vc {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .down-load-btn {
        position: absolute;
        cursor: pointer;
        top: 24px;
        right: 24px;
        width: 298px;
        height: 44px;
        border-radius: 2px;
        border: 1px solid #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;

        .down-text {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 22px;
        }
        .active-download-text {
            color: #000;
        }
        .down-load-img {
            width: 24px;
        }
        .code-show {
            position: absolute;
            width: 298px;
            height: 298px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 343px;
            background: #fff;
            box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
            &::before {
                content: "";
                position: absolute;
                border-top: 20px solid transparent;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-bottom: 20px solid #fff;
                width: 0;
                height: 0;
                margin-top: -313px;
                margin-left: -32px;
            }
            img {
                width: 200px;
            }
        }
    }
    .active-download-btn-bg {
        background-color: rgb(204, 217, 248);
        border: 1px solid rgb(204, 217, 248);
    }

    .copy-right {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 25px;
    }
    .main-area {
        flex: 1;
        .logo-row {
            display: flex;
            align-items: center;
            justify-content: center;

            margin-bottom: 50px;
            .logo-img {
                width: 180px;
                cursor: pointer;
            }
            .mobileSize{
                width: 140px;
            }
        }
        .login-area {
            margin-left: auto;
            margin-right: auto;
            background-color: #fff;
            border-radius: 20px;
            max-width: 450px;
            box-sizing: border-box;

            .vc {
                padding: 47px 57px;
                .phone-box{
                    display: flex;
                    align-items: center;
                }
                .phone-wrap {
                    border-radius: 8px;
                    border: 1px solid #efefef;
                    // justify-content: center;
                    line-height: 36px;
                    padding: 0px 12px;
                    margin-bottom: 24px;
                    height: 36px;
                    .area-code {
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 22px;
                        padding-right: 20px;
                        margin-right: 20px;
                        border-right: 1px solid #ccc;
                    }
                    .phone-input {
                        width: 100%;
                        height: 20px;
                        border: none;
                        outline: none;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        // font-weight: 400;
                        color: rgba(51, 51, 51, 1);
                        line-height: 30px;
                    }
                    .phone-input::placeholder {
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #CCCCCC;
                    }
                }
                .password-box{
                    // width: 336px;
                    height: 62px;
                    display: flex;
                    align-items: center;
                    // flex-direction: column;
                    .pass-wd{
                        flex:1;
                    }
                    p{
                        height: 30px;
                        // line-height: 30px;
                        color: #666666 
                    }
                    img{
                        width: 24px;
                        height: 24px;
                        margin-right: 15px;

                    }
                    // display: flex;
                }
                .code-wrap {
                    border-radius: 8px;
                    border: 1px solid #efefef;
                    display: flex;
                    align-items: center;
                    padding: 3px 12px;
                    // margin-bottom: 70px;
                    .code-input {
                        width: 100%;
                        height: 30px;
                        border: none;
                        outline: none;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        // color: rgba(51, 51, 51, 1);
                        // color: #cccccc;
                        line-height: 22px;
                    }
                    .code-input::placeholder {
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        // color: rgba(153, 153, 153, 1);
                        color: #cccccc;
                    }
                    .get-code {
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        // color: #999;
                        color: #4A82F4;
                        line-height: 22px;
                        margin-left: 5px;
                        word-break: keep-all;
                        pointer-events: none;
                    }
                    .getCodeBtnActive {
                        color: #4a82f4 !important;
                        pointer-events: unset !important;
                    }
                }
                .loginBtn{
                        display: flex;
                        justify-content: center;
                        margin-top: 32px;
                        margin-bottom: 12px;
                        .login-btn{
                            color: #fff;
                            width: 316px;
                            height: 38px;
                            background: #4A82F4;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 3px;
                        }
                    }
                // .login-btn {
                //     background: #4a82f4;
                //     border-radius: 35px;
                //     font-size: 18px;
                //     font-family: PingFangSC-Regular, PingFang SC;
                //     font-weight: 400;
                //     color: #ffffff;
                //     line-height: 50px;
                //     text-align: center;
                //     margin-bottom: 20px;
                // }
                .sign-up {
                    text-align: center;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 20px;
                    .link-text {
                        text-decoration: none;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #4a82f4;
                        line-height: 20px;
                    }
                }
            }
            .bind-vc {
                padding: 200px 0 240px 0;
                text-align: center;
                .bind-wechat {
                    font-size: 20px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 28px;
                    margin-bottom: 28px;
                }
                .skip-btn {
                    display: inline-block;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 42px;
                    text-align: center;
                    border-radius: 2px;
                    border: 1px solid #cccccc;
                    padding: 0 30px;
                    margin-right: 30px;
                }
                .bind-btn {
                    display: inline-block;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 42px;
                    padding: 0 15px;
                    background: #4a82f4;
                    border-radius: 2px;
                    border: 1px solid #4a82f4;
                }
            }
        }
    }
}
.center {
    text-align: center;
}
.tip-text {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 25px;
}
.go-btn {
    display: inline-block;
    background: #4a82f4;
    text-decoration: none;
    padding: 0 15px;
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 34px;
    text-align: center;
    margin-top: 25px;
}
.blue-text {
    margin-top: 25px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4a82f4;
    line-height: 22px;
}
.hight-pl {
    height: 90px;
}
@media (max-width: 767px) {
    .hight-pl {
        height: 103px;
    }
    #appp .main-area .login-area .vc {
        padding: 61px 0;
        .phone-wrap {
            border-radius: 8px;
            border: 1px solid #efefef;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            margin-bottom: 15px;
            max-width: 260px;
            margin-left: auto;
            margin-right: auto;
            .area-code {
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                // color: #333333;
                line-height: 18px;
                padding-right: 10px;
                margin-right: 10px;
                border-right: 1px solid #ccc;
            }
            .phone-input {
                width: 100%;
                height: 30px;
                border: none;
                outline: none;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                // color: #333;
                line-height: 18px;
            }
            .phone-input::placeholder {
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #cccccc;
                line-height: 18px;
            }
        }
        .code-wrap {
            border-radius: 8px;
            border: 1px solid #efefef;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            margin-bottom: 22px;
            max-width: 260px;
            margin-left: auto;
            margin-right: auto;
            .code-input {
                width: 100%;
                height: 30px;
                border: none;
                outline: none;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #cccccc !important;
                line-height: 18px;
            }
            .code-input::placeholder {
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #cccccc;
                line-height: 18px;
            }
            .get-code {
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999;
                line-height: 22px;
                margin-left: 5px;
                word-break: keep-all;
                pointer-events: none;
            }
            .getCodeBtnActive {
                color: #4a82f4 !important;
                pointer-events: unset !important;
            }
        }
        .login-btn {
            background: #4a82f4;
            border-radius: 35px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 34px;
            text-align: center;
            margin-bottom: 10px;
            max-width: 237px;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
        }
        .sign-up {
            text-align: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
            .link-text {
                text-decoration: none;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #4a82f4;
                line-height: 20px;
            }
        }
    }
    #appp .copy-right {
        font-size: 12px;
    }
    #appp {
        .main-area .logo-row {
            margin-bottom: 24px;
        }
        .down-load-btn {
            top: 20px;
            right: 15px;
            width: 200px;
            height: 28px;
            border-radius: 2px;
            border: 1px solid #ffffff;
            .down-text {
                font-size: 11px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                line-height: 16px;
            }
            .active-download-text {
                color: #000;
            }
            .down-load-img {
                width: 13px;
            }
            .code-show {
                position: absolute;
                width: 200px;
                height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 230px;
                background: #fff;
                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
                &::before {
                    content: "";
                    position: absolute;
                    border-top: 20px solid transparent;
                    border-left: 20px solid transparent;
                    border-right: 20px solid transparent;
                    border-bottom: 20px solid #fff;
                    width: 0;
                    height: 0;
                    margin-top: -218px;
                    margin-left: -32px;
                }
                img {
                    width: 180px;
                }
            }
        }
    }
}
</style>
